exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-xcad-js": () => import("./../../../src/pages/buy-xcad.js" /* webpackChunkName: "component---src-pages-buy-xcad-js" */),
  "component---src-pages-creators-js": () => import("./../../../src/pages/creators.js" /* webpackChunkName: "component---src-pages-creators-js" */),
  "component---src-pages-fans-js": () => import("./../../../src/pages/fans.js" /* webpackChunkName: "component---src-pages-fans-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lulu-99-js": () => import("./../../../src/pages/lulu99.js" /* webpackChunkName: "component---src-pages-lulu-99-js" */),
  "component---src-pages-play-guide-js": () => import("./../../../src/pages/play-guide.js" /* webpackChunkName: "component---src-pages-play-guide-js" */),
  "component---src-pages-play-js": () => import("./../../../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-plugin-js": () => import("./../../../src/pages/plugin.js" /* webpackChunkName: "component---src-pages-plugin-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-watch-2-earn-js": () => import("./../../../src/pages/watch2earn.js" /* webpackChunkName: "component---src-pages-watch-2-earn-js" */),
  "component---src-pages-xgrant-js": () => import("./../../../src/pages/xgrant.js" /* webpackChunkName: "component---src-pages-xgrant-js" */)
}

